import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

const setStateNoop: Dispatch<SetStateAction<boolean>> = () => {}

type NavWindowContextType = [boolean, Dispatch<SetStateAction<boolean>>]

const NavWindowContext = createContext<NavWindowContextType>([
  false,
  setStateNoop,
])

export const useNavWindow = () => {
  const navState = useContext(NavWindowContext)

  return navState
}

interface NavWindowProviderProps {
  children: ReactElement
}

export const NavWindowProvider = ({ children }: NavWindowProviderProps) => {
  const navState = useState(false)

  return (
    <NavWindowContext.Provider value={navState}>
      {children}
    </NavWindowContext.Provider>
  )
}
